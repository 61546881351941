.sign-position-container {
    position: relative;
}

.sign-position-container:after {
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\00000a\00000aUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\00000a\00000aDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\00000a\00000aExcepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\00000a\00000aLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\00000a\00000aUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\00000a\00000aDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\00000a\00000aExcepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    font-size: 10px;
    color: #bbb;
    white-space: pre-wrap;
    padding: 10px;
}

.sign-position-item {
    cursor: move;
    display: inline-block;
    position: absolute;
    color: #00028B;
    font-size: 10px;
    line-height: 10px;
    background-color: white;
}

.sign-position-item .title {
    padding: 0 7px;
    background-color: #00028B;
    color: white;
    text-align: center;
    line-height: 10.5px;
}

.sign-positions {
    position: relative;
    display: inline-block;
    line-height: 0;
}